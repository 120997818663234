import CustomerSuccess from './pages/CustomerSuccess';
import './App.css';

function App() {
  return (
    <div className="App">
      <CustomerSuccess />
    </div>
  );
}

export default App;
