const constants = {
  getFreeTrialButtonText: "Get Free Trial",
  watchDemoButtonText: "Watch Demo",
  customerSuccess: {
    team: "Customer Success",
    intro: {
      heading: "Gen AI assistant for Customer Success Agents",
      description: "Use NeoGPT to assist your analyst teams with everything from report generation from chain",
    },
    dashboardImg: require("./static/images/customer-success-dashboard.png"),
    dashboardImgAlt: "Customer Success Dashboard",
    testimonial: {
      message: "“Send about million emails business, It's really important personal having newsletter”",
      profile: "Andrew Jack",
      company: "Motilal Oswal",
      profileIcon: require("./static/images/jack-icon.png")
    },
    features: {
      item1: "Dynamic Answers",
      iconItem1: require("./static/images/dynamic-answers.png"),
      item2: "Conversation Analysis",
      iconItem2: require("./static/images/conversation-analysis.png"),
      item3: "Agent Onboarding",
      iconItem3: require("./static/images/agent-onboarding.jpg"),
      item4: "User Data Insights",
      iconItem4: require("./static/images/user-data-insights.png"),
    },
    animation: {
      heading: "Deliver Customer Satisfaction with Quick Resolutions",
      description: "NeoGPT assists customer success agents close queries with 5x lower turnaround time and more enriched reponses from customer data."
    },
    copilot: {
      heading: "1 copilot for Customer success agents",
      description: "Leverage generative AI within your existing tools and workflows to supercharge your workflows 10x and be more efficient."
    },
    card: {
      heading: "Delight customers with fast and insightful query resolution",
      imgPath: require("./static/images/customer-success-turnaround-time.png"),
      imgAlt: "Turnaround time",
      benefit1: {
        heading: "Dynamic Answers",
        description: "Personalize responses on the basis of user chat history and the latest product information",
        img: require("./static/images/dynamic-answers-icon.png"),
        imgAlt: "Dynamic Answers"
      },
      benefit2: {
        heading: "Customer Insights",
        description: "Draw insights from customer data and include them in your answer seamlessly",
        img: require("./static/images/customer-insights-icon.png"),
        imgAlt: "Customer Insights"
      }
    },
    featureCard1: {
      heading: "Automate Agent Onboarding Training",
      description: "Onboard new customer agents seamlessly with NeoGPT guiding them through guidelines for good customer interactions and how to avoid bad replies.",
      img: require("./static/images/onboarding-time.png"),
      imgAlt: "Onboarding Time"
    },
    featureCard2: {
      heading: "Customer Satisfaction Analysis",
      description: "Extract new insights like sentiment, feature focus, and recurring requests on customer satisfaction and use it to improve your customer sucess pipline.",
      img: require("./static/images/customer-satisfaction-analysis.png"),
      imgAlt: "Customer Satisfaction Analysis"
    },
    dataViz: {
      heading: "Integrated with the best Customer Support tools",
      description: "Along with different internal customer data APIs and other private wikis on",
      imgPaths: [
        require("./static/images/salesforce.png"),
        require("./static/images/hubspot.png"),
        require("./static/images/freshchat.png"),
        require("./static/images/freshsales.png"),
        require("./static/images/bitrix24.png"),
        require("./static/images/click-up.png"),
        require("./static/images/sap.png"),
        require("./static/images/zendesk.png"),
        require("./static/images/mondaycom.png"),
        require("./static/images/intercom.png"),
        require("./static/images/hubspot.png"),
        require("./static/images/freshsales.png"),
      ]
    },
    blog: {
      heading: "Resources for customer success teams",
      btn1: "All",
      btn2: "Query Resolution",
      btn3: "Customer NPS"
    }
  },
}

export default constants