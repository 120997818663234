const IMAGES = {
  onfinanceLogo: require("./onfinance-logo.png"),
  profile1: require("./profile-1.png"),
  profile2: require("./profile-2.png"),
  profile3: require("./profile-3.png"),
  grid: require("./grid.png"),
  csAnimationGraphic: require("./cs-animation-graphic.png")
};

export default IMAGES;
