import React, {useEffect, useState} from 'react'
import IMAGES from "../static/images"

function CustomerSuccessAnimation() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isDesktop) {
    return (
      <div className="my-10 bg-[#001E27] grid grid-cols-2 gap-x-10 p-20 max-[1400px]:px-32 max-[1200px]:px-24 mx-20 rounded-3xl relative">
        <div className="absolute top-[50%] left-[10%] translate-y-[-50%] h-[50%] w-[40%]"></div>
        <div className="absolute right-0">
          <img src={IMAGES.grid} alt="" />
        </div>
        <div>
          <img className="scale-[1.35]" src={IMAGES.csAnimationGraphic} alt="" />
        </div>
        <div className="ml-5">
          <div className="flex flex-col text-left">
            <div className="font-poppins-extraBold text-[2.5vw] free-trial-heading mb-5">
              Collaborate to build high quality reports at light speed
            </div>
            <div className="font-poppins-regular text-[1vw] text-[#C2E3FF] my-5">
              NeoGPT separates chain of thought from the PDF report outputs allowing teams to collaborate, version and iterate on reports.
            </div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="bg-white text-center rounded-2xl font-poppins-regular font-semibold text-[1.25vw] px-9 py-5 mt-5 w-fit">
                <h3 className="cs-get-trial-btn">Get Free Trial</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="my-10 bg-[#001E27] py-20 px-10 relative">
        <div className="absolute top-0 right-0">
          <img src={IMAGES.grid} alt="" />
        </div>
        <div className="ml-5">
          <div className="flex flex-col text-left">
            <div className="font-poppins-extraBold text-[8vw] free-trial-heading mb-5">
              Collaborate to build high quality reports at light speed
            </div>
            <div className="font-poppins-regular text-[6vw] text-[#C2E3FF] my-5">
              NeoGPT separates chain of thought from the PDF report outputs allowing teams to collaborate, version and iterate on reports.
            </div>
            <a href="mailto:anuj@onfinance.in?subject=NeoGPT%20Free%20Trial&body=Please%20activate%20a%20NeoGPT%20free%20trial%20version%20for%20my%20organization.%20Regards">
              <button className="bg-white text-center rounded-2xl font-poppins-regular font-semibold text-[5vw] px-9 py-5 mt-5">
                <h3 className="cs-get-trial-btn">Get Free Trial</h3>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default CustomerSuccessAnimation