import React, { useEffect, useState } from "react";

function Card({
  cardHeading,
  feature1ImgPath,
  feature1ImgAlt,
  feature1Heading,
  feature1Description,
  feature2ImgPath,
  feature2ImgAlt,
  feature2Heading,
  feature2Description,
  card1Img,
  card1ImgAlt,
}) {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 850);
  const handleResize = () => {
    setIsDesktop(window.innerWidth >= 850);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const features = (imgPath, imgAlt, heading, description) => {
    return (
      <div className="px-3 pb-10">
        <div className="w-[30%] rounded-xl bg-slate-300 max-[850px]:mx-auto mb-5">
          <img src={imgPath} alt={imgAlt} />
        </div>
        <div className="grid grid-rows-[60px] text-left max-[850px]:text-center">
          <div className="font-poppins-bold py-2 self-start text-[1.05vw] max-[850px]:text-[4vw] cs-card-benefit-heading">
            {heading}
          </div>
          {isDesktop ? (
            <div className="font-poppins-regular py-2 self-start text-[1.05vw] max-[850px]:text-[2vw] cs-card-benefit-description">
              {description}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="mx-auto px-[8%]">
      <div className="rounded-2xl mx-auto my-10 p-16 max-[1200px]:p-8 min-[850px]:grid min-[850px]:grid-cols-3 min-[850px]:gap-x-[5%] cs-card-bg">
        <div className="min-[850px]:col-start-1 min-[850px]:col-end-3">
          <div className="h-[100%] grid grid-rows-[40%_60%] max-[1400px]:grid-rows-[30%_70%] max-[850px]:grid-rows-2">
            <div className="font-poppins-extraBold text-[2.25vw] max-[850px]:text-[7vw] text-left max-[850px]:text-center py-5 px-3 cs-card-heading">
              {cardHeading}
            </div>
            <div className="grid grid-cols-2 min-[850px]:items-start text-left my-5 place-items-center">
              {features(
                feature1ImgPath,
                feature1ImgAlt,
                feature1Heading,
                feature1Description
              )}
              {features(
                feature2ImgPath,
                feature2ImgAlt,
                feature2Heading,
                feature2Description
              )}
            </div>
          </div>
        </div>
        <div className="min-[900px]:col-start-3 min-[900px]:col-end-4 place-self-center h-[80%]">
          <img
            className="rounded-lg block my-auto"
            src={card1Img}
            alt={card1ImgAlt}
          />
        </div>
      </div>
    </div>
  );
}

export default Card;
