import Blog from "../components/Blog";
import Body from "../components/Body";
import DataVizTools from "../components/DataVizTools";
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import constants from "../constants";

function CustomerSuccess() {
  return (
    <div>
      <Navbar />
      <Body team={constants.customerSuccess} />
      <DataVizTools dataViz={constants.customerSuccess.dataViz}></DataVizTools>
      <Blog
        blog={constants.customerSuccess.blog}
        team={constants.customerSuccess.team}
      ></Blog>
      <Footer />
    </div>
  );
}

export default CustomerSuccess;
